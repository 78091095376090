@import "../../vars.module.scss";

.crawler-status {
  text-align: center;
  padding: 3px 10px;
  width: 90px;
  border-radius: 4px;

  &.ok {
    background-color: #def0fe;
    color: #053ac8;
  }

  &.error {
    color: #7019cf;
    background-color: #7019cf33;
  }
}

.type-client {
  text-align: center;
  padding: 3px 10px;
  width: 90px;
  border-radius: 4px;

  &.client {
    background-color: #def0fe;
    color: #053ac8;
  }

  &.trial {
    color: #7019cf;
    background-color: #7019cf33;
  }
}

.container-companies {
  padding: 40px;
  color: #000000;

  .company-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    span {
      color: $turivius_light;
      font-weight: bold;
    }
  }

  .company-search {
    margin: 40px 0px;

    .input-search {
      border-radius: 4px;
    }

    .button-search {
      background-color: $secondary-dark;
      color: $white;
      border-radius: 4px;
    }
  }

  .company-body {
    height: 100vh;

    .rs-table-cell-content {
      display: flex;
      align-items: center;
      font-size: 15px;
    }

    .rs-table-row {
      cursor: pointer;

      &.rs-table-row-header {
        cursor: default;
      }
    }

    .rs-table-cell-wrap {
      width: 100%;
    }
  }
}
