@import "../../vars.module.scss";


.crawler-status {
	text-align: center;
	padding: 3px 10px;
	width: 90px;
	border-radius: 4px;
  
	&.ok {
	  background-color: #def0fe;
	  color: #053ac8;
	}
  
	&.error {
	  color: #7019cf;
	  background-color: #7019cf33;
	}
  }

.status-ok {
    color: #1e7e34;  /* Texto verde mais escuro */
    background-color: #d4edda; /* Fundo verde claro */
    // border: 2px solid #007bff;  /* Borda azul */
	text-align: center;
	padding: 3px 10px;
	width: 90px;
	border-radius: 4px;
}

.status-atenção {
    color: #ff8c00;  /* Texto laranja */
    background-color: #fff3cd; /* Fundo amarelo suave */
    // border: 2px solid #ff8c00;  /* Borda laranja */
	text-align: center;
	padding: 3px 10px;
	width: 90px;
	border-radius: 4px;
}

.status-com-furo {
    color: #dc3545;  /* Texto vermelho */
    background-color: #f8d7da; /* Fundo vermelho claro */
    // border: 2px solid #dc3545;  /* Borda vermelha */
	text-align: center;
	padding: 3px 10px;
	width: 90px;
	border-radius: 4px;
}

.status-quebrado {
    color: #721c24;  /* Texto vermelho escuro */
    background-color: #f2d7d5; /* Fundo vermelho escuro claro */
    // border: 2px solid #721c24;  /* Borda vermelho escuro */
    text-align: center;
	padding: 3px 10px;
	width: 90px;
	border-radius: 4px;
}



.entities-status-tags {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
	
}

.status-tag {
    background-color: #f4f4f4;
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.status-tag p {
    margin: 0;
    font-weight: bold;
}

.tag {
    display: block;
    font-size: 12px;
    color: #007bff;
}


.container-entities {
	padding: 40px;
	color: #000000;

	.entities-header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 30px;

		span {
			color: $turivius_light;
			font-weight: bold;
		}
	}

	.entities-search {
		margin: 40px 0px;

		.input-search {
			border-radius: 4px;
		}

		.button-search {
			background-color: $secondary-dark;
			color: $white;
			border-radius: 4px;
		}
	}

	.entities-body {
		margin-top: 30px;
		height: 100vh;

		.rs-table-cell-content {
			display: flex;
			align-items: center;
			font-size: 15px;
		}

		.rs-table-row {
			cursor: pointer;

			&.rs-table-row-header {
				cursor: default;
			}
		}

		.rs-table-cell-wrap {
			width: 100%;
		}
	}
}
